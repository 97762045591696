<template>
  <b-modal :visible="visible" title="Informações do Cliente" @hide="onHide">
    <div>
      <p><strong>Nome:</strong> {{ customer.name || 'Sem Informações' }}</p>
      <p><strong>Email:</strong> {{ customer.email || 'Sem Informações' }}</p>
      <p>
        <strong>Telefone:</strong> {{ customer.phone || 'Sem Informações' }}
      </p>
      <p>
        <strong>Documento:</strong> {{ customer.document || 'Sem Informações' }}
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CustomerInfoModal',
  props: {
    customer: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    onHide() {
      this.$emit('close')
    }
  }
}
</script>

<template>
  <Layout>
    <HomeList v-if="true" />
    <div v-else class="d-flex justify-content-center">
      <div class="spinner-border" role="status"></div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import HomeList from '@/components/Home/HomeList'

export default {
  name: 'HomeIndexView',
  components: { Layout, HomeList }
}
</script>
